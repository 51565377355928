#block-adaptive-form-callme {
  .modal-dialog {
    margin: 0 0 0 auto;
    max-width: 460px;
    height: 100%;
    background-color: #ffffff;

    .contact-message-callback-form.modal-content {
      padding: 264px 80px 0 73px;
      background: none;
      box-shadow: 0 !important;
      margin: 0;

      .close {
        background: url(../images/close-icons-callme.png) no-repeat 0 0;
      }

      .modal-header {
        padding: 0;
        border: 0;
      }

      .modal-title {
        text-align: left;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: #2C2E38;
      }

      .modal-info {
        margin-top: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #2C2E38;
      }

      .modal-body {
        padding: 0;
      }

      form {
        .form-actions {
          border: 0;

          input.form-submit {
            width: 100%;
          }
        }

        input.form-control {
          border: 0;
          border-bottom: 1px solid #808080;
          border-radius: 0;
          color: #2C2E38;
          padding: 13px 0px;

          &:visited {
            background-color: transparent;
          }
        }

        ::-webkit-input-placeholder {
          color: #2C2E38;
        }

        ::-moz-placeholder {
          color: #2C2E38;
        }

        :-moz-placeholder {
          color: #2C2E38;
        }

        :-ms-input-placeholder {
          color: #2C2E38;
        }
      }
    }
  }
}
